<template>
    <section>
        <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Contratti Privati </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Contratti Annullati</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" />
          </div>
        </b-col>
      </b-row>

      <b-table
        :fields="fields"
        :items="contracts"
        :busy="loading"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        responsive
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati dei contratti...</strong>
          </div>
        </template>

        <template #empty>
          <h5 class="text-primary-light text-center my-2">
            Non ci sono contratti
          </h5>
        </template>
        
        <template #emptyfiltered>
          <h5 class="text-primary-light text-center my-2">
            Non sono stati trovati contratti
          </h5>
        </template>

        <template #cell(id_contratto)="data">
            {{data.item.id_ordine}}
        </template>

        <template #cell(nome_responsabile)="data">
            {{data.item.nome}} {{data.item.cognome}}
        </template>

        <template #cell(telefono_responsabile)="data">
            {{data.item.cellulare}}
        </template>

        <template #cell(e-mail_responsabile)="data">
            {{data.item.email}}
        </template>

        <template #cell(opzioni)>
          <span class="text-nowrap">
            <feather-icon class="cursor-pointer" icon="EyeIcon" size="20" v-b-tooltip.hover.top.v-primary-light="'Dettagli'"/>
            <feather-icon v-if="user.account_type == 'manager'" class="ml-2 cursor-pointer" icon="EditIcon" size="20" v-b-tooltip.hover.top.v-primary-light="'Modifica'"/>
          </span>
        </template>
      </b-table>
      </b-card>

    </section>
</template>

<script>

    import {BRow, BCol, BButton, BBreadcrumb, BBreadcrumbItem, BCard, BTable, BSpinner, BFormInput, VBTooltip} from 'bootstrap-vue'

    import {Requests} from '@/api'

    export default {
        components: {
            BRow,
            BCol,
            BButton,
            BBreadcrumb,
            BBreadcrumbItem,
            BCard,
            BTable,
            BSpinner,
            BFormInput,
            VBTooltip
        },

        directives: {
          'b-tooltip' : VBTooltip
        },

        data: () => ({

            user: {},

            fields: [
                {
                    key: "id_contratto",
                    sortable: true
                },
                {
                    key: "data_ordine",
                    sortable: true
                },
                {
                    key: "venditore",
                    sortable: true
                },
                {
                    key: "cliente",
                    sortable: true
                },
                {
                    key: "marca",
                    sortable: true
                },
                {
                    key: "modello",
                    sortable: true
                },
                {
                    key: "versione",
                    sortable: true
                },
                {
                    key: "targa",
                    sortable: true
                },
                {
                    key: "opzioni",
                    sortable: true
                },
            ],

            filter: null,
            contracts: [],
            
            //Helpers

            perPage: 15,
            currentPage: 1,
            loading: false,
        }),

        created() {
            this.getContracts();

            this.user = localStorage.getItem('user');
        },  

        methods: {

            async getContracts() {
                this.loading = true;

                try {
                    const response = await Requests.getContracts('bocciato');
                    this.contracts = response;
                } catch(err) {
                    console.debug(err)
                }

                this.loading = false;
            }
        }
    }

</script>

